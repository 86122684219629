import React, {useEffect, useState} from "react"
import {Grid, IconButton, TextField, Tooltip} from "@mui/material"
import _ from "lodash/fp"
import SaveIcon from "@mui/icons-material/Save"
import {Cancel} from "@mui/icons-material"
import {useBaseTranslation} from "../../../app/baseTranslationContext"

const MailChimpInputFields = ({museumSettings, handleSaveMailchimpSettings, isUmbrellaFields, readonly}) => {
    const t = useBaseTranslation()
    const [mcAudienceId, setMcAudienceId] = useState("")
    const [initAudienceId, setInitAudienceId] = useState("")
    const [mcApiKey, setMcApiKey] = useState("")
    const [initApiKey, setInitApiKey] = useState("")

    useEffect(() => {
        if (museumSettings) {
            setMcApiKey(museumSettings?.mcApiKey)
            setInitApiKey(museumSettings?.mcApiKey)
            setMcAudienceId(museumSettings?.mcAudienceId)
            setInitAudienceId(museumSettings?.mcAudienceId)
        }
    }, [museumSettings])

    useEffect(() => {
        if (readonly) {
            setMcApiKey("")
            setMcAudienceId("")
        }
    }, [readonly])

    return (
        <Grid container
              spacing={3}
              direction="column">
            <Grid container
                  item
                  spacing={1}
                  xs={12}>

                <Grid item
                      xs>
                    <TextField variant="outlined"
                               size="small"
                               fullWidth
                               value={mcAudienceId || ""}
                               disabled={readonly}
                               onChange={e => setMcAudienceId(e.target.value)}
                               helperText={isUmbrellaFields ?
                                           t("termsOfSaleCommonUrlHelperText") :
                                           t("mailchimpMuseumHelperText")}
                               label={`Audience id`}/>
                </Grid>

                <Grid item
                      xs>
                    <TextField variant="outlined"
                               size="small"
                               fullWidth
                               value={mcApiKey || ""}
                               disabled={readonly}
                               onChange={e => setMcApiKey(e.target.value)}
                               label={`Api key`}/>
                </Grid>
                <Grid item>
                    <Tooltip title={t("cancel")}>
                            <span>
                                <IconButton
                                    disabled={(_.isEqual(initAudienceId, mcAudienceId) && _.isEqual(initApiKey,
                                        mcApiKey)) || readonly}
                                    onClick={() => {
                                        setMcAudienceId(initAudienceId)
                                        setMcApiKey(initApiKey)
                                    }}
                                    size="large">
                                    <Cancel/>
                                </IconButton>
                            </span>
                    </Tooltip>
                    <Tooltip title={t("save")}>
                            <span>
                                <IconButton
                                    disabled={(_.isEqual(initAudienceId, mcAudienceId) && _.isEqual(initApiKey,
                                        mcApiKey)) && readonly}
                                    onClick={() => handleSaveMailchimpSettings(mcAudienceId,
                                        mcApiKey,
                                        isUmbrellaFields)}
                                    size="large">
                                    <SaveIcon/>
                                </IconButton>
                            </span>
                    </Tooltip>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default MailChimpInputFields