import React from "react"
import {Typography} from "@mui/material"

const MailchimpSettingsHelperText = () => {
    return (
        <>
            <Typography variant="subtitle2">
                Mailchimp innstillinger fylles ut i forbindelse med nyhetsbrev utsendelser.
            </Typography>
            <br/>
            <Typography variant="body2">
                Mailchimp instillingene audience id og api key finner du inne på mailchimp kontoen tilknyttet ditt
                museum/selskap.
            </Typography>
        </>
    )
}

export default MailchimpSettingsHelperText