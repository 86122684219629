// noinspection JSUnresolvedVariable

import makeStyles from '@mui/styles/makeStyles';
import React, {useCallback, useEffect, useState} from "react";
import {
    PRODUCT_TYPE_ENTRANCE_TICKET,
    PRODUCT_TYPE_EVENT,
    PRODUCT_TYPE_SEASONAL_PASS,
    useBaseContextState
} from "../baseContext";
import SectionHeader from "../components/SectionHeader";
import {
    SET_SELECTED_MUSEUM,
    useMuseumSelectorDispatch,
    useMuseumSelectorState
} from "../museum-selector/museumSelectorContext";
import ProductEditorButtons from "./ProductEditorButtons";
import {useProductEditorTranslation} from "./productEditorTranslationContext";
import ScrollableSectionView from "../ScrollableSectionView";
import clsx from "clsx";
import {useHistory, useParams} from "react-router-dom";
import {useAxiosBpn, useAxiosMuseums} from "../axios";
import useCustomSnackbar from "../components/useCustomSnackbar";
import EditorCardModule from "../components/EditorCardModule";
import GeneralSettings from "./general-settings/GeneralSettings";
import ProductCategoriesAndPrices from "./product-categories-and-prices/ProductCategoriesAndPrices";
import ScheduleEvents from "./schedule-events/ScheduleEvents";
import {ProgressIndicator} from "../ProgressIndicator";
import {toCamelCase, toPascalCase} from "../helper-functions/stringUtility";
import {
    getBaseRouteBasedOnProductType,
    getBaseUrlBasedOnProductType
} from "../helper-functions/getBaseUrlBasedOnProductType";
import _, {lowerFirst} from "lodash";
import {useAuthsState} from "../auths/authsContext";
import ProductPriceCategoryHelperText from "./product-categories-and-prices/ProductPriceCategoryHelperText";
import {getMuseum} from "../endpoints/museums";
import {ENTRANCE_BASE_URL, EVENTS_BASE_URL, SEASONAL_PASSES_BASE_URL} from "../Routes";
import ProductsSettings from "../../settings/products-settings/ProductsSettings";
import ProductEditorCalendar from "./product-editor-calendar/ProductEditorCalendar";
import PreviewToolbar from "./PreviewToolbar";
import ProductEditorPreview from "./prodiuct-editor-preview/ProductEditorPreview";
import ProductValidity from "./product-validity/ProductValidity";
import RegistrationFormAddons from "./product-registration-form-addons/RegistrationFormAddons";
import ProductDiscountCodes from "./product-discount-codes/ProductDiscountCodes";
import {ProductTranslationProvider} from "./ProductTranslationContext";
import {
    getTranslatableFieldValueFromObject,
    getTranslationsPayloadFromObject
} from "../helper-functions/translationHelpers";
import VismaSettings from "./visma-settings/VismaSettings";
import RelatedProducts from "./related-products/RelatedProducts";
import ProductFamilyDiscounts from "./product-family-discounts/ProductFamilyDiscounts";
import ProductLinkCard from "./product-link-card/ProductLinkCard";
import ProductUniqueSellingPoints from "./product-unique-selling-points/ProductUniqueSellingPoints";
import {ADMIN_APP_ID, getRoleByMuseumId} from "../menu/MuseumSelectorHelpers";
import {ROLES} from "../menu/MuseumSelector";

const useStyles = makeStyles((theme) => ({
    container: {
        height: "100%",
        overflowY: "hidden",
    },
    contained: {
        width: "50%",
        height: "100%",
        float: "left",
        overflowY: "auto",
        paddingRight: theme.spacing(1),
    },
    editor: {
        [theme.breakpoints.down('md')]: {
            width: "100%",
        },
        [theme.breakpoints.only("lg")]: {
            width: "50%",
        },
        [theme.breakpoints.only("xl")]: {
            width: "40%",
        },
    },
    preview: {
        backgroundColor: theme.palette.background.dark,
        height: "100%",
        [theme.breakpoints.down('md')]: {
            display: "none",
        },
        [theme.breakpoints.only("lg")]: {
            width: "50%",
        },
        [theme.breakpoints.only("xl")]: {
            width: "60%",
        },
    },
    previewCard: {
        opacity: 0.7,
        position: "absolute",
        top: "200px",
        textAlign: "center",
        right: "100px",
        zIndex: 1000,
        padding: theme.spacing(4),
        [theme.breakpoints.down('md')]: {
            display: "none",
        },
        [theme.breakpoints.only("md")]: {
            width: "30%",
            right: "125px",
        },
        [theme.breakpoints.only("lg")]: {
            width: "40%",
            right: "140px",
        },
        [theme.breakpoints.only("xl")]: {
            width: "50%",
            right: "190px",
        },
    },
}))

const LOCAL_STORAGE_KEY = "bpn.productEditorSelectedPreview"

const ProductEditor = ({type}) => {
    const classes = useStyles()
    const t = useProductEditorTranslation()
    const history = useHistory()
    const snackbar = useCustomSnackbar()
    const {selectedMuseum, umbrellaId} = useMuseumSelectorState()
    const dispatch = useMuseumSelectorDispatch()
    const {currentBaseUrl} = useBaseContextState()
    const {userName, userAccessMuseumIds, userData} = useAuthsState()
    const {id: _id} = useParams()
    const [id, setId] = useState(_id !== "new" ? parseInt(_id) : _id)
    const [activeBreadcrumb, setActiveBreadcrumb] = useState("")
    const [unsavedChanges, setUnsavedChanges] = useState(false)
    const [unsavedScheduleEventChanges, setUnsavedScheduleEventChanges] = useState(false)
    const [currentEndpointUrl, setCurrentEndpointUrl] = useState("")
    const [product, setProduct] = useState()
    const [familyDiscounts, setFamilyDiscounts] = useState([])
    const [selectedPreview, setSelectedPreview] = useState(localStorage.getItem(LOCAL_STORAGE_KEY) || "preview")
    const [detailsLink, setDetailsLink] = useState()
    const [refetchCategoriesAndPrices, setRefetchCategoriesAndPrices] = useState(false)
    const [refetchFamilyDiscounts, setRefetchFamilyDiscounts] = useState(true)
    const [refetchDiscountCodes, setRefetchDiscountCodes] = useState(false)
    const [refetchUniqueSellingPoints, setRefetchUniqueSellingPoints] = useState(false)
    const [vismaExport, setVismaExport] = useState()
    const [productCategoriesAndPricesAlert, setProductCategoriesAndPricesAlert] = useState()
    const isAppAdmin = userData?.appAccess?.apps?.find(app => app.id === window._env_.REACT_APP_ID && app.role.name === ROLES.ADMIN)
    const isGlobalEkulturAdmin = userData?.appAccess?.apps?.find(app => app.id === ADMIN_APP_ID && app.role.name === ROLES.ADMIN)

    const [{data: productData, loading: productLoading}, getProduct] = useAxiosBpn({
        url: `${currentEndpointUrl}/${id}`,
        params: {
            expandRecurring: false,
            includeTestTickets: true,
        },
    }, {manual: true})

    const [{data: postData, loading: postLoading, error: postError}, postProduct] = useAxiosBpn({
        method: "POST",
    }, {manual: true})

    const [{data: putData, loading: putLoading, error: putError}, putProduct] = useAxiosBpn({
        method: "PUT",
    }, {manual: true})

    const [{data: museumData}, _getMuseum] = useAxiosMuseums({}, {manual: true})
    const [{data: familyDiscountsData}, getFamilyDiscounts] = useAxiosBpn({
        url: `family_discounts/museums/${umbrellaId}`,
    }, {manual: true})

    const [{data: voucherSettingsData}] = useAxiosBpn({
        url: `voucher_settings/museums/${umbrellaId}`,
    })

    const [{data: museumSettingsData}, getMuseumSettings] = useAxiosBpn({
        url: `museum_settings/${selectedMuseum?.id}`,
    })

    useEffect(() => {
        getMuseumSettings().catch(() => {
            // Just to avoid annoying console error
        })
    },[getMuseumSettings])


    const breadcrumbs = [
        {label: t(toCamelCase(type) + "s"), url: currentBaseUrl},
        {label: t("list"), url: `${currentBaseUrl}/list`},
        {label: activeBreadcrumb},
    ]

    const updateProductState = useCallback((data) => setProduct(data), [])

    useEffect(() => {
        if (unsavedScheduleEventChanges) {
            setUnsavedChanges(true)
        }
    }, [unsavedScheduleEventChanges])


    useEffect(() => {
        if (refetchFamilyDiscounts) {
            getFamilyDiscounts()
            setRefetchFamilyDiscounts(false)
        }
    }, [getFamilyDiscounts, refetchFamilyDiscounts])

    useEffect(() => {
        if (type === PRODUCT_TYPE_EVENT && id !== "new") {
            setDetailsLink(`${EVENTS_BASE_URL}/${id}/details`)
        } else if (type === PRODUCT_TYPE_ENTRANCE_TICKET && id !== "new") {
            setDetailsLink(`${ENTRANCE_BASE_URL}/${id}/details`)
        } else if (type === PRODUCT_TYPE_SEASONAL_PASS && id !== "new") {
            setDetailsLink(`${SEASONAL_PASSES_BASE_URL}/${id}/details`)
        }
    }, [type, id])

    useEffect(() => {
        if (product?.id && !_.isEqual(id, product.id)) {
            setId(parseInt(product.id))
        }
    }, [product, id])

    useEffect(() => {
        localStorage.setItem(LOCAL_STORAGE_KEY, selectedPreview)
    }, [selectedPreview])

    useEffect(() => {
        if (id !== "new" && product?.museumId && selectedMuseum?.id !== product?.museumId && product?.museumId !== 0) {
            _getMuseum({url: `museums/${product?.museumId}`}).catch(() => {
                // Just to avoid annoying console error
            })
        }
    }, [id, selectedMuseum, product, _getMuseum])

    useEffect(() => {
            if (id === "new") {
                setProduct(undefined)
            } else {
                if (currentEndpointUrl && currentEndpointUrl !== "") {
                    getProduct({url: `${currentEndpointUrl}/${id}`}).catch(() => {
                        // Just to avoid annoying console error
                    })
                }
            }
        }, [currentEndpointUrl, getProduct, id],
    )

    useEffect(() => {
        if (productData) {
            switch (type) {
                case PRODUCT_TYPE_EVENT:
                    updateProductState(populateTranslateAbleFieldsFromObject(productData.event))
                    break
                case PRODUCT_TYPE_ENTRANCE_TICKET:
                    updateProductState(populateTranslateAbleFieldsFromObject(productData.entranceTicket))
                    break
                case PRODUCT_TYPE_SEASONAL_PASS:
                    updateProductState(populateTranslateAbleFieldsFromObject(productData.seasonalPass))
                    break
                default:
                    updateProductState(populateTranslateAbleFieldsFromObject(productData))
                    break
            }
        }
    }, [productData, type, updateProductState])

    useEffect(() => {
        if (familyDiscountsData) setFamilyDiscounts(familyDiscountsData.familyDiscounts)
    }, [familyDiscountsData])

    useEffect(() => {
        setCurrentEndpointUrl(getBaseUrlBasedOnProductType(type))
    }, [type])

    useEffect(() => {
        if (museumData) {
            dispatch({
                type: SET_SELECTED_MUSEUM,
                payload: {
                    ...museumData?.museum,
                    role: getRoleByMuseumId(museumData?.museum?.id, userAccessMuseumIds, isAppAdmin, isGlobalEkulturAdmin)
                }
            })
            snackbar.showSuccess(`${t("selectedMuseumChanged")} ${lowerFirst(t("to"))} ${museumData?.museum?.name}`)
        }
    }, [museumData, dispatch, snackbar, t, userAccessMuseumIds, isAppAdmin, isGlobalEkulturAdmin])

    useEffect(() => {
        if (postData) {
            snackbar.showSuccess(`${t(toCamelCase(type))} ${t("added").toLowerCase()}`)
            updateProductState(postData[toCamelCase(type)])
            setUnsavedChanges(false)
            setUnsavedScheduleEventChanges(false)
            history.push(`/${getBaseRouteBasedOnProductType(type)}/${postData[toCamelCase(type)].id}/edit`)
        }
    }, [history, id, postData, snackbar, t, type, updateProductState])

    useEffect(() => {
        if (putData) {
            snackbar.showSuccess(`${t(toCamelCase(type))} ${t("updated").toLowerCase()}`)
            setUnsavedChanges(false)
            setUnsavedScheduleEventChanges(false)
            getProduct({url: `${currentEndpointUrl}/${id}`}).catch(() => {
                // Just to avoid annoying console error
            })
        }
    }, [id, putData, snackbar, t, getProduct, type, currentEndpointUrl])

    useEffect(() => {
        if (voucherSettingsData) {
            const {voucherSettings} = voucherSettingsData
            setVismaExport(voucherSettings?.format && voucherSettings?.format === "visma")
        }
    }, [voucherSettingsData])

    useEffect(() => {
        if (postError) snackbar.showError(t(postError.message))
        if (putError) snackbar.showError(t(putError.message))
    }, [postError, putError, snackbar, t])

    useEffect(() => {
        if (product) {
            if (id === "new" && !product?.museumName) {
                setProduct(prevState => ({
                    ...prevState,
                    museumId: selectedMuseum.id,
                    museumName: selectedMuseum.name,
                }))
            } else {
                if (product?.museumId !== 0 && !product?.museumName) {
                    getMuseum(product?.museumId).then(({name}) => {
                        setProduct(prevState => ({...prevState, museumName: name}))
                    })
                }
            }
        }
    }, [id, selectedMuseum, product])

    useEffect(() => {
        if (product) {
            if (id === "new") {
                setActiveBreadcrumb(t(`new${type ? toPascalCase(type) : ""}`) + ` (${product?.museumName})`)
            } else {
                setActiveBreadcrumb(`${product?.name?.value} (${product?.museumName})`)
            }
        }
    }, [id, product, t, type])

    function getScheduleEventsSubheader() {
        switch (type) {
            case PRODUCT_TYPE_EVENT:
                return t("scheduleEventsModuleEventHelperText")
            case PRODUCT_TYPE_ENTRANCE_TICKET:
                return t("scheduleEventsModuleEntranceTicketHelperText")
            default:
                return null
        }
    }

    const populateTranslateAbleFieldsFromObject = (objectToPopulate) => {
        if (objectToPopulate) {
            objectToPopulate.name = getTranslatableFieldValueFromObject(objectToPopulate, "name")
            objectToPopulate.description = getTranslatableFieldValueFromObject(objectToPopulate, "description")
            objectToPopulate.shortDescription = getTranslatableFieldValueFromObject(objectToPopulate, "shortDescription")
            objectToPopulate.registrationFormFreetextField = getTranslatableFieldValueFromObject(objectToPopulate,
                "registrationFormFreetextField")
            objectToPopulate.relatedProductsHeading = getTranslatableFieldValueFromObject(objectToPopulate,
                "relatedProductsHeading")
            if (objectToPopulate.linkCard) {
                objectToPopulate.linkCard.heading = getTranslatableFieldValueFromObject(objectToPopulate.linkCard,
                    "heading")
                objectToPopulate.linkCard.subHeading = getTranslatableFieldValueFromObject(objectToPopulate.linkCard,
                    "subHeading")
            }
        }
        return objectToPopulate
    }

    const hasNewsLetterConfigured = () => {
        return (museumSettingsData?.museum?.mcApiKey && museumSettingsData?.museum?.mcAudienceId) ||
          (museumSettingsData?.umbrella?.mcApiKey && museumSettingsData?.umbrella?.mcAudienceId)
    }


    const saveProduct = (status) => {
        const linkCard = product.linkCard
        // had to delete then add since it is a prop with translations (it would mess up for other translations)
        delete product.linkCard

        if (status) {
            product.status = status
        }

        if (!product?.createdBy) {
            product.createdBy = userName
        }

        if (!product?.maxValildations) {
            product.maxValildations = null
        }

        product.updatedBy = userName

        if (!product?.newsletterOptionEnabled) {
            product.newsletterOptionEnabled = false
        }

        if(hasNewsLetterConfigured()) {
            product.newsletterOptionEnabled = true
        }

        product.translations = getTranslationsPayloadFromObject(product)
        product.prices = product?.prices?.map(price => {
            return {
                ...price,
                name: price.name?.value,
                description: price.description?.value,
                translations: getTranslationsPayloadFromObject(price),
            }
        })

        product.scheduleEvents = product?.scheduleEvents?.map(scheduleEvent => {
            return {
                ...scheduleEvent,
                id: Number.isInteger(scheduleEvent.id) ? scheduleEvent.id : null,
                name: scheduleEvent.name?.value,
                translations: getTranslationsPayloadFromObject(scheduleEvent),
            }
        })

        if (linkCard) {
            product.linkCard = {
                heading: linkCard.heading?.value,
                subHeading: linkCard.subHeading?.value,
                translations: getTranslationsPayloadFromObject(linkCard)
            }
        }

        if (id === "new") {
            postProduct({url: currentEndpointUrl, data: product}).catch(() => {
                // Just to avoid annoying console error
            })
        } else {
            putProduct({url: `${currentEndpointUrl}/${id}`, data: product}).catch(() => {
                // Just to avoid annoying console error
            })
        }
    }

    return (
        <ProductTranslationProvider>
            <SectionHeader breadcrumbs={breadcrumbs}
                           backButton={true}
                           detailsLink={detailsLink}>
                <ProductEditorButtons updatedAt={product?.updatedAt}
                                      unsavedChanges={unsavedChanges}
                                      status={product?.status}
                                      saveProduct={saveProduct}
                                      loading={postLoading || putLoading}/>
            </SectionHeader>
            <ScrollableSectionView breadcrumbs={false}>
                {productLoading ? <ProgressIndicator/>
                    : <div className={classes.container}>
                        <div className={clsx(classes.contained, classes.editor)}>
                            <EditorCardModule subheader={t("generalSettingsModuleHelperText")}
                                              expanded={true}
                                              colorButtonTooltip={t("productColorHeaderChangeHelperText")}
                                              title={"generalSettings"}>
                                <GeneralSettings product={product}
                                                 type={type}
                                                 unsavedChanges={unsavedChanges}
                                                 setUnsavedChanges={setUnsavedChanges}
                                                 updateProductState={updateProductState}/>
                            </EditorCardModule>
                            {vismaExport && (
                                <EditorCardModule title={"vismaSettings"}
                                                  subheader={t("vismaSettingsHelperText")}>
                                    <VismaSettings product={product}
                                                   unsavedChanges={unsavedChanges}
                                                   setUnsavedChanges={setUnsavedChanges}
                                                   updateProductState={updateProductState}/>
                                </EditorCardModule>
                            )}
                            <EditorCardModule subheader={t("registrationFormCustomizationHelperText")}
                                              title={"registrationFormCustomizationTitle"}>
                                <RegistrationFormAddons product={product}
                                                        productType={type}
                                                        unsavedChanges={unsavedChanges}
                                                        setUnsavedChanges={setUnsavedChanges}
                                                        updateProductState={updateProductState}/>
                            </EditorCardModule>
                            {type === PRODUCT_TYPE_SEASONAL_PASS &&
                                <EditorCardModule subheader={t("validityModuleHelperText")}
                                                  title={t("productValidity")}>
                                    <ProductValidity product={product}
                                                     unsavedChanges={unsavedChanges}
                                                     setUnsavedChanges={setUnsavedChanges}
                                                     updateProductState={updateProductState}/>
                                </EditorCardModule>}
                            {type !== PRODUCT_TYPE_SEASONAL_PASS && <EditorCardModule
                                subheader={getScheduleEventsSubheader()}
                                title={"calendar"}>
                                <ScheduleEvents product={product}
                                                unsavedChanges={unsavedScheduleEventChanges}
                                                updateProductState={updateProductState}
                                                setUnsavedChanges={setUnsavedScheduleEventChanges}/>
                            </EditorCardModule>}
                            <EditorCardModule settingsDialogTitle={t("productSettings")}
                                              onSettingsDialogClose={() => setRefetchCategoriesAndPrices(!refetchCategoriesAndPrices)}
                                              settingsDialogComponent={<ProductsSettings showSectionHeader={false}
                                                                                         showFamilyDiscountModule={false}
                                                                                         showUniqueSellingPointsModule={false}
                                                                                         showDiscountCodeModule={false}/>}
                                              subheader={t("productCategoriesAndPricesModuleHelperText")}
                                              helperTextComponent={<ProductPriceCategoryHelperText/>}
                                              alert={productCategoriesAndPricesAlert}
                                              title={"productCategoriesAndPrices"}>
                                <ProductCategoriesAndPrices product={product}
                                                            unsavedChanges={unsavedChanges}
                                                            refetch={refetchCategoriesAndPrices}
                                                            updateProductState={updateProductState}
                                                            setAlert={setProductCategoriesAndPricesAlert}
                                                            setUnsavedChanges={setUnsavedChanges}/>
                            </EditorCardModule>
                            {familyDiscounts?.length > 0 &&
                                <EditorCardModule title={"familyDiscounts"}
                                                  subheader={t("productFamilyDiscountsModuleHelperText")}
                                                  onSettingsDialogClose={() => setRefetchFamilyDiscounts(!refetchFamilyDiscounts)}
                                                  settingsDialogComponent={<ProductsSettings showSectionHeader={false}
                                                                                             showPriceCategoryModule={false}
                                                                                             showProductGroupModule={false}
                                                                                             showUniqueSellingPointsModule={false}
                                                                                             showDiscountCodeModule={false}/>}>
                                    <ProductFamilyDiscounts availableFamilyDiscounts={familyDiscounts}
                                                            product={product}
                                                            unsavedChanges={unsavedChanges}
                                                            setUnsavedChanges={setUnsavedChanges}
                                                            updateProductState={updateProductState}/>
                                </EditorCardModule>
                            }
                            <EditorCardModule settingsDialogTitle={t("discountCodes")}
                                              subheader={t("discountCodeModuleSubheading")}
                                              onSettingsDialogClose={() => setRefetchDiscountCodes(!refetchDiscountCodes)}
                                              settingsDialogComponent={<ProductsSettings showSectionHeader={false}
                                                                                         showDiscountCodeModule={true}
                                                                                         showFamilyDiscountModule={false}
                                                                                         showUniqueSellingPointsModule={false}
                                                                                         showPriceCategoryModule={false}
                                                                                         showProductGroupModule={false}/>}
                                              title={"discountCode"}>
                                <ProductDiscountCodes product={product}
                                                      unsavedChanges={unsavedScheduleEventChanges}
                                                      setUnsavedChanges={setUnsavedChanges}
                                                      reFetchDiscountCodes={refetchDiscountCodes}
                                                      updateProductState={updateProductState}/>
                            </EditorCardModule>
                            <EditorCardModule settingsDialogTitle={t("uniqueSellingPoints")}
                                              subheader={t("uniqueSellingPointsSubheading")}
                                              onSettingsDialogClose={() => setRefetchUniqueSellingPoints(!refetchUniqueSellingPoints)}
                                              settingsDialogComponent={<ProductsSettings showSectionHeader={false}
                                                                                         showDiscountCodeModule={false}
                                                                                         showFamilyDiscountModule={false}
                                                                                         showPriceCategoryModule={false}
                                                                                         showProductGroupModule={false}/>}
                                              title={"uniqueSellingPoint"}>
                                <ProductUniqueSellingPoints product={product}
                                                            unsavedChanges={unsavedScheduleEventChanges}
                                                            setUnsavedChanges={setUnsavedChanges}
                                                            refetchUniqueSellingPoints={refetchUniqueSellingPoints}
                                                            updateProductState={updateProductState}/>
                            </EditorCardModule>
                            <EditorCardModule title={"productLinkCard"}
                                              subheader={t("productLinkCardModuleHelperText")}>
                                <ProductLinkCard product={product}
                                                 unsavedChanges={unsavedChanges}
                                                 productType={type}
                                                 updateProductState={updateProductState}
                                                 setUnsavedChanges={setUnsavedChanges}/>
                            </EditorCardModule>
                            <EditorCardModule subheader={t("relatedProductsSubheading")}
                                              title={"relatedProducts"}>
                                <RelatedProducts product={product}
                                                 unsavedChanges={unsavedScheduleEventChanges}
                                                 setUnsavedChanges={setUnsavedChanges}
                                                 updateProductState={updateProductState}/>
                            </EditorCardModule>
                        </div>
                        <div className={clsx(classes.contained, classes.preview)}>
                            <PreviewToolbar selectedPreview={selectedPreview}
                                            setSelectedPreview={setSelectedPreview}/>
                            {selectedPreview === "calendar" && (
                                <ProductEditorCalendar product={product}
                                                       unsavedChanges={unsavedScheduleEventChanges}
                                                       refetchProduct={getProduct}/>
                            )}
                            {selectedPreview === "preview" && (
                                <ProductEditorPreview product={product}
                                                      unsavedChanges={unsavedChanges}/>
                            )}
                        </div>
                    </div>
                }
            </ScrollableSectionView>
        </ProductTranslationProvider>
    )
}

export default ProductEditor
