import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import {initReactI18next} from "react-i18next"

const commonNbNO = {
    "3rdPartySolution": "Tredjepartsløsning",
    access: "Tilgang",
    activateForThisProduct: "Aktiver for dette produktet",
    activateKdrIntegration: "Aktivér KDR-integrasjon",
    active: "Aktiv",
    active2: "Aktive",
    passed: "Forbigåtte",
    activeEvents: "Aktive arrangementer",
    noActiveEvents: "Ingen aktive kalenderhendelser",
    noPassedEvents: "Ingen forbigåtte kalenderhendelser",
    addException: "Legg til unntak",
    add: "Legg til",
    added: "Lagt til",
    addFilter: "Legg til filter",
    addNewEvent: "Legg til ny hendelse",
    addNewPrice: "Legg til ny pris",
    addPaymentCard: "Legg til betalingskort",
    addPaymentType: "Legg til betaling",
    addPointOfSaleSystem: "Legg til kassasystem",
    confirmedOnly: "Kun betalte (ikke krediterte)",
    address: "Adresse",
    addTicketCategory: "Legg til billettype",
    affiliation: "Tilhørighet",
    afresh: "På nytt",
    after: "Etter",
    again: "Igjen",
    agenda: "Agenda",
    all: "Alle",
    allDates: "Alle datoer",
    allEvents: "Alle hendelser",
    allPaymentTypes: "Alle betalingsmåter",
    alternative: "Alternativ ",
    AmericanExpress: "American Express",
    Amount: "Beløp",
    AmountCaptured: "Beløp trukket",
    AmountCredited: "Beløp kreditert",
    and: "Og",
    andOr: "Og/eller",
    andOrAtTheLatest: "Og/eller senest",
    anErrorOccurred: "En feil oppsto",
    Annuled: "Annullert",
    Annulled: "Annullert",
    answer: "Svar",
    api: "API",
    apiClient: "API-klient",
    apiClients: "API-klienter",
    approved: "Godkjent",
    priorityOrderChanged: "Prioriteringsrekkefølge endret",
    archive: "Arkiver",
    archived: "Arkivert",
    select: "Velg",
    discounts: "Rabatter",
    AuthorizationId: "Autorisasjons-ID",
    Authorized: "Autorisert",
    availableFrom: "Tilgjengelig fra",
    availableTo: "Tilgjengelig til",
    base: "Base",
    backgroundColor: "Bakgrunnsfarge på nettsiden og e-postkvittering",
    backgroundImage: "Bakgrunnsbilde på nettsiden",
    BatchNumber: "Buntnummer",
    boughtAsGift: "Kjøpt som gave",
    Browser: "Nettleser",
    buttonColor: "Knappefarge på nettsiden og e-postkvittering",
    by: "Av",
    calendar: "Kalender",
    cancel: "Avbryt",
    canValidateMultipleTimes: "Kan valideres mer enn en gang",
    cancelled: "Avlyst",
    cancelled_by_user: "Avbrutt av bruker",
    cancelOrder: "Kansellér ordre",
    cancelScheduleEvent: "Avlys hendelse",
    limit: "Begrensning",
    limits: "Begrensninger",
    priority: "Prioritet",
    canNotAddPriceCategoryWithoutProductGroup: "Alle priskategorier må knyttes til en varegruppe. Legg til én eller flere varegrupper først",
    canNotAddFamilyDiscountWithoutPriceCategory: "Alle familierabatter må knyttes til en priskategori. Legg til én eller flere priskategorier først",
    capacity: "Kapasitet",
    CardInformation: "Kortinformasjon",
    cardNumber: "Kortnummer",
    cardType: "Korttype",
    chooseColor: "Velg farge",
    chooseDate: "Velg dato",
    cId: "kID",
    className: "Klasse",
    critical: "Kritisk",
    clear: "Fjern",
    clickAdd: "Trykk 'legg til'",
    close: "Lukk",
    code: "Kode",
    collapseAll: "Slå sammen",
    collectZipCodes: "Lagre postnummer til kunder",
    columns: "Kolonner",
    colorPalette: "Fargepallett",
    columnTitle: "Kolonnetittel",
    comfortable: "Komfortabelt",
    comment: "Kommentar",
    externalComment: "Kommentar fra kunde",
    internalComment: "Intern kommentar",
    comments: "Kommentarer",
    commentField: "Kommentarfelt",
    commonMailChimpSettingsFor: "Felles Mailchimp  innstillinger for",
    commonUrlFor: "Felles URL for",
    compact: "Kompakt",
    confirmed: "Bekreftet",
    confirmed_credit_note: "Kreditnota",
    confirmed_partial_credit_note: "Delkreditnota",
    confirmed_credited: "Kreditert",
    confirmed_partial_credited: "Delkreditert",
    confirmed_paid: "Betalt",
    connectedTo: "Tilknyttet",
    contains: "Inneholder",
    containerBackgroundColor: "Bakgrunnsfarge handlekurv og merkelapper",
    containerBorderRadius: "Radius for avrunding av hjørner",
    CoopIkano: "Coop Ikano",
    countsAs: "Telles som",
    created: "Opprettet",
    createdAt: "Opprettet",
    createdBy: "Opprettet av",
    createdFor: "Opprettet for",
    creditAccount: "Kreditkonto",
    creditNote: "Kreditnota",
    creditNoteCreated: "Kreditnota opprettet",
    creditOrder: "Kreditér ordre",
    creditTaxCode: "Kreditavgiftskode",
    creditTurnoverClass: "Kreditomsetningsklasse",
    Currency: "Valuta",
    current: "Gjeldende",
    custom: "Egendefinert",
    customer: "Kunde",
    customerDeletedSuccessfully: "Kunde slettet",
    customers: "Kunder",
    CustomerEntered: "Kunde kom inn",
    customerId: "Kunde-ID",
    customerInfo: "Kundeinformasjon",
    CustomerInformation: "Kundeinformasjon",
    customerInput: "Kundens input",
    customerAnswer: "Kundens svar",
    CustomerIPCountry: "Kunde-IP-land",
    CustomerRedirected: "Kunde redirigert",
    customRepetition: "Egendefinert gjentagelse",
    daily: "Daglig",
    dashboard: "Dashboard",
    date: "Dato",
    dateFiltering: "Datofiltrering",
    dateGrouping: "Datogruppering",
    DateTime: "Dato- og klokkeslett",
    day: "Dag",
    debitAccount: "Debetkonto",
    debitAccountMissing: "Standard debetkonto mangler",
    debitAccountOnPaymentCards: "Debetkonto på betalingskort",
    debitTaxCode: "Debetavgiftskode",
    debitTurnoverClass: "Debetomsetningsklasse",
    delete: "Slett",
    deleted: "Slettet",
    deleteScheduleEvent: "Slett hendelse",
    density: "Tetthet",
    department: "Avdeling",
    departmentId: "Avdelings-ID",
    departmentAdded: "Avdeling lagt til",
    Description: "Beskrivelse",
    description: "Beskrivelse",
    DinersClubInternational: "Diners Club International",
    discountCode: "Rabattkode",
    discountCodes: "Rabattkoder",
    discountCodeTimesUsed: "ordre har brukt rabattkoden",
    domainId: "Domene-ID",
    download: "Last ned",
    downloadAsCSV: "Last ned CSV",
    downloadXLSX: "Last ned XLSX",
    draft: "Utkast",
    edit: "Rediger",
    edited: "Redigert",
    email: "Epost",
    emailStatus: "Status (epost)",
    emailSentAt: "Sendt (epost)",
    enableNewsletterForProduct: "Nyhetsbrev påmelding",
    enableTranslations: "Gjør produkt tilgjengelig på flere språk",
    showTranslations: "Vis oversettelser",
    showImageOnProductPage: "Vis bilde på produktsiden",
    endDate: "Sluttdato",
    ends: "Avsluttes",
    endsWith: "Slutter på",
    endTime: "Til",
    endTimeKnown: "Sluttidspunkt kjent",
    entrance: "Inngang",
    entrance_ticket: "Inngangsbillett",
    entranceTicket: "Inngangsbillett",
    entranceTickets: "Inngangsbilletter",
    equals: "Er lik",
    Error: "Feil",
    ErrorLog: "Feillogg",
    event: "Arrangement",
    events: "Arrangementer",
    every: "Hver",
    exceptions: "Unntak",
    expandAll: "Utvid alle",
    expiryDate: "Gyldig til",
    ExpiryDate: "Utløpsdato",
    export: "Eksportér",
    exported: "Eksportert",
    exportedAt: "Eksportert",
    exportedBy: "Eksportert av",
    externalId: "Ekstern ID",
    failed: "Feilet",
    Fee: "Avgift",
    fields: "Felter",
    filter: "Filtrér",
    filterOnDate: "Filtrér på dato",
    filterOnOrderDate: "Filtrér på ordredato",
    filterOnPaymentType: "Filtrér på betalingstype",
    filterOnScheduleEvent: "Filtrér på Kalenderhendelse",
    filters: "Filtre",
    filterValue: "Filterverdi",
    findColumn: "Finn kolonne",
    finished: "Ferdig",
    firstName: "Fornavn",
    firstNameGiftReceiver: "Fornavn (gavemottaker)",
    for: "For",
    forEach: "For hver",
    format: "Format",
    forSelectedMuseumOrUmbrella: "For valgt museum eller selskap/stiftelse",
    freetextField: "Fritekstfelt",
    freeTextFieldPlaceholderText: "Fritekstfelt ledetekst",
    friday: "Fredag",
    fridays: "Fredager",
    from: "Fra",
    fromDate: "Fra dato",
    generalInformation: "Generell informasjon",
    generalSettings: "Generelle innstillinger",
    generate: "Generér",
    generateMissingVouchers: "Generér manglende regnskapsbilag",
    getKdrOrder: "Hent (KDR)",
    giftProduct: "Gaveprodukt",
    googleTagManager: "Google tag manager",
    googleTagManagerSettings: "Google tag manager settings",
    goTo: "Gå til",
    goToCreditedOrder: "Gå til kreditert ordre",
    goToCreditNote: "Gå til kreditnota",
    goToSettings: "Gå til innstillinger",
    groupOn: "Gruppér på",
    groupTicket: "Gruppebillett",
    Gyldighet: "Gyldighet",
    handlerEmail: "Håndterers epost",
    handlerName: "Håndterers navn",
    hasNoPriceCategories: "Har ingen priskategorier",
    hasTranslation: "Har oversettelse",
    hide: "Gjem",
    hideAll: "Gjem alle",
    hideFilters: "Gjem filtre",
    History: "Logg",
    homepageUrl: "Hjemmeside URL",
    id: "Id",
    icon: "Ikon",
    imageBrightness: "Lysstyrke",
    imageBlur: "Uskarphet",
    imageSaturation: "Fargemetning",
    in: "I",
    includeInVisitorStatistics: "Inkludér i besøkstall",
    internal_failure: "Intern feil",
    internalId: "Intern ID",
    IP: "IP",
    IPCountryMatchesIssuingCountry: "IP-land matcher utstederland",
    is: "Er",
    isAllDay: "Ingen klokkeslett",
    isBefore: "Er før",
    isFullDay: "Ingen klokkeslett",
    isNot: "Er ikke",
    isOnOrAfter: "Er på eller etter",
    isOnOrBefore: "Er på eller før",
    Issuer: "Utsteder",
    issuer_is_undetermined: "Utsteder mangler",
    IssuerCountry: "Utstedsland",
    IssuerId: "Utsteds-ID",
    journalType: "Bilagsart",
    kdr: "KDR",
    kdrExported: "Eksportert (KDR)",
    kdrOrderIsNotConfirmed: "KDR-ordre er ikke bekreftet",
    kdrOrderQueried: "KDR-spørring kjørt",
    kdrQuery: "Hent ordredata fra KDR",
    kdrStatus: "Status (KDR)",
    key: "Nøkkel",
    lastFiveOrderlines: "Siste fem ordrelinjer",
    lastCommentBy: "Kommentarfelt sist endret av",
    lastName: "Etternavn",
    lastNameGiftReceiver: "Etternavn (gavemottaker)",
    lastUpdated: "Sist oppdatert",
    lastYear: "Forrige år",
    latest: "Senest",
    latestEvents: "Siste arrangementer",
    latestSaleFor: "Siste salg for",
    limitSale: "Plassbegrensning",
    museumSvgLogo: "SVG logo",
    maxPerCustomer: "Max antall billetter per kunde",
    linesPrPage: "Linjer per side",
    list: "Liste",
    location: "Lokasjon",
    lockedByGlobalAdmin: "Låst av admin",
    logEntries: "Loggoppføringer",
    error: "Feil",
    Maestro: "Maestro",
    mailchimpSettings: "Mailchimpinnstillinger",
    rubicSettings: "Rubicinnstillinger",
    museumInformation: "Museumsinformasjon",
    newDepartment: "Ny avdeling",
    screenName: "Visningsnavn",
    orgNr: "Organisasjonsnummer",
    zipCode: "Postkode",
    city: "Poststed",
    street: "Gateadresse",
    mainMuseum: "Hovedmuseum",
    mainMuseumId: "Hovedmuseum (id)",
    mainTicket: "Samlebillett",
    mandatoryAnswer: "Påkrevd svar",
    manual_handling: "Manuell håndtering",
    manualHandling: "Manuell håndtering",
    MaskedPAN: "Maskert kortnummer",
    MasterCard: "Mastercard",
    maxNumberOfOccurrences: "Maks antall gjentagelser",
    mcashId: "Ordre-ID",
    memberships: "Medlemskap",
    menu_id_missing: "Meny-ID mangler",
    menuId: "Meny-ID",
    menuIds: "Meny-IDer",
    menuIdsForEachMuseumInFoundation: "Standard meny-ID for hvert enkelt museum i gjeldende selskap/konsolidering",
    merchant: "Forhandler",
    MerchantId: "Forhandler-ID",
    merchantId: "Forhandler-ID",
    message: "Beskjed",
    monday: "Mandag",
    ticketValidation: "Billettvalidering",
    includeValidatedTicketsInVisitorStats: "Inkludér validerte billetter i besøkstall",
    mondays: "Mandager",
    month: "Måned",
    role: "Rolle",
    monthly: "Månedlig",
    multipleChoice: "Flervalg",
    museum: "Museum",
    museum_name: "Museum",
    museumGroup: "Selskap",
    museumId: "Museum (id)",
    museumName: "Museum",
    museums: "Museer",
    museumSelectorLabel: "Velg museum",
    mustHaveAvailableFrom: "Må ha tilgjengelig fra-dato",
    mustHaveCategory: "Må ha priskategori",
    mustHaveName: "Må ha et navn",
    mustHaveProductGroup: "Må ha varegruppe",
    name: "Navn",
    netaxept: "Netaxept",
    netaxeptQuery: "Netaxeptspørring",
    never: "Aldri",
    new: "Ny",
    newContent: "Nytt innhold",
    newEntrance: "Ny inngangsbillett",
    newEntranceTicket: "Ny inngangsbillett",
    newestSale: "Siste salg",
    newEvent: "Nytt arrangement",
    newProduct: "Nytt produkt",
    newSeasonalPass: "Nytt sesongkort",
    newsletter: "Nyhetsbrev",
    nextEvents: "Kommende arrangementer",
    nextMonth: "Neste måned",
    nextPage: "Neste side",
    noAccess: "Ingen tilgang",
    no: "Nei",
    noDataToShow: "Ingen data å vise",
    noDate: "Ingen dato",
    noDepartmentsDefined: "Ingen avdelinger er definert for dette museet",
    noEmailAddressRegistered: "Ingen epostadresse registrert",
    noEntranceTicketsYet: "Ingen inngangsbilletter enda",
    noEventsYet: "Ingen arrangementer enda",
    noMoreAvailablePaymentCards: "Ingen flere tilgjengelige betalingskort",
    noMoreAvailablePaymentTypes: "Ingen flere tilgjengelige betalingstyper",
    noMoreAvailablePointOfSaleSystems: "Ingen flere tilgjengelige kassasystemer",
    non: "Ingen",
    noName: "Mangler navn",
    none: "Ingen",
    noPaymentCardsAdded: "Ingen betalingskort lagt til",
    noPaymentTypesAdded: "Ingen betalingsmåter lagt til",
    noPointOfSaleSystemsAdded: "Ingen kassasystemer lagt til",
    noPricesYet: "Ingen priser enda",
    noRows: "Ingen rader",
    noScheduleEventsYet: "Dette produktet har foreløpig ingen kalenderhendelser",
    not: "Ikke",
    notActive: "Ikke aktiv",
    noTermsOfSaleUrlDefined: "URL til kjøpsbetingelser er ikke definert",
    notImplementedYet: "Ikke implementert enda",
    notPossibleToAddPriceWithoutCategory: "Det er ikke mulig å legge inn pris uten priskategori",
    notRegistered: "Ikke registrert",
    notSent: "Ikke sendt",
    occurrences: "Ganger",
    ok: "OK",
    on: "På",
    onlyConfirmed: "Kun bekreftede",
    onlyNew: "Kun \"nye\"",
    Operation: "Operasjon",
    operators: "Operatorer",
    or: "Eller",
    order: "Ordre",
    orderConfirmation: "Ordrebekreftelse",
    orderDate: "Ordredato",
    OrderDescription: "Ordrebeskrivelse",
    orderDetails: "Ordredetaljer",
    orderHistory: "Ordrehistorikk",
    orderId: "Ordre-ID",
    OrderInformation: "Ordreinformasjon",
    orderIsNotConfirmed: "Ordre er ikke bekreftet/betalt",
    orderLines: "Ordrelinjer",
    orderLinesWithNoVouchers: "Ordrelinjer uten bilag",
    orderNo: "Ordrenummer",
    OrderNumber: "Ordrenummer",
    orderNumber: "Ordrenummer",
    orderProcessed: "Ordre prosessert",
    overrideOrderStatus: "Overstyr ordrestatus",
    emailMissing: "Epost mangler",
    emailNotification: "Ordrenotifikasjon",
    emailNotificationAddress: "E-postadresse for ordrenotifikasjon",
    noAvailableDiscountCodesForThisProduct: "Ingen rabattkoder tilgjengelig for dette produktet",
    noAvailableDiscountCodesForTheseProducts: "Ingen rabattkoder tilgjengelig for disse produktene",
    orderSummary: "Oppsummering av ordre",
    orderProcessing: "Ordreprosessering",
    noOrderLinesYet: "Ingen ordrelinjer enda",
    selectEvent: "Velg tidspunkt",
    selectScheduleEvent: "Velg kalenderhendelse",
    onlySendToCustomersFor: "Send kun til kunder for",
    processedWithNetaxept: "Transaksjon forsøkt gjennomført",
    orderQueried: "Netaxeptspørring kjørt",
    orderReport: "Ordrerapport",
    orderStatus: "Ordrestatus",
    other: "Andre",
    Other: "Annet",
    overview: "Oversikt",
    ownMailchimpSettingForMuseum: "Egen mailchimp setting for museum",
    ownRubicApiUrlForMuseum: "Egen rubic api url for museum",
    ownTagManagerCodeForMuseum: "Egen google tag manager for museum",
    ownUrlForMuseum: "Egen URL for museum",
    paid: "Betalt",
    participants: "Deltakere",
    partnerId: "Partner-ID",
    payment: "Betaling",
    payment_pending: "Betaling venter",
    payment_type_name: "Betalingstype",
    paymentCard: "Betalingskort",
    paymentCardsUpdatedSuccessfully: "Betalingskort oppdatert",
    PaymentError: "Betalingsfeil",
    PaymentMethod: "Betalingsmetode",
    paymentSettings: "Betalingsinnstillinger",
    paymentSolution: "Betalingsløsning",
    paymentType: "Betalingsmåte",
    paymentTypeName: "Betalingstype",
    paymentTypes: "Betalingsmåter",
    pcs: "Stk",
    phone: "Telefon",
    pointOfSaleSystem: "Kassasystem",
    pointOfSaleSystems: "Kassasystemer",
    pointOfTime: "Tidspunkt",
    posId: "POS-ID",
    postalCode: "Postnummer",
    longDescription: "Utfyllende beskrivelse - vises under infoknappen i kjøpsportalen (valgfritt felt)",
    previousPage: "Forrige side",
    previousMonth: "Forrige måned",
    productSelector: "Produktvelger",
    price: "Pris",
    discount: "Rabatt",
    discountPercentage: "Rabatt (%)",
    excludeFromCapacityCalculation: "Ekskludér fra plassbegrensning",
    discountCodeUseLimit: "Gyldig antall ganger",
    price_category_name: "Priskategori",
    priceAmount: "Beløp",
    priceCategories: "Priskategorier",
    priceCategory: "Priskategori",
    priceCategoryName: "Priskategori",
    prices: "Priser",
    processAFresh: "Prosessér på nytt",
    product: "Produkt",
    product_name: "Produkt",
    product_type: "ProduktType",
    productCategoriesAndPrices: "Kategorier og priser",
    productDetails: "Produktdetaljer",
    productEditor: "Produkteditor",
    productLinkCard: "Lenkekort",
    productGroup: "Varegruppe",
    productGroupId: "Varegruppe (id)",
    productGroups: "Varegrupper",
    productId: "Produkt-ID",
    productionEnvironment: "Produksjonsmiljø",
    productMustBePublishedFirst: "Produktet må publiseres først",
    productName: "Produkt",
    products: "Produkter",
    productSettings: "Produktinnstillinger",
    productType: "ProduktType",
    producttypes: "Produkttyper",
    project: "Prosjekt",
    prPriceCategory: "Pr. priskategori",
    published: "Publisert",
    publishedOnly: "Kun publiserte",
    quantity: "Antall",
    quantityOfTickets: "Antall billetter",
    QueryFinished: "Spørring ferdig",
    showOnlyNonJoint: "Vis kun utilknyttede",
    question: "Spørsmål",
    giftedTo: "Gave til",
    receipt: "Kvittering",
    receiptPage: "Kvitteringsside",
    recurring: "Gjentagelse",
    recurringOnThisScheduleEvent: "Gjentagelser på denne kalenderhendelsen",
    recurs: "Gjentas",
    recursNot: "Gjentas ikke",
    refused_by_issuer: "Nektet av utsteder",
    referrer: "Henvisning",
    registrationFormCustomizationTitle: "Registreringsskjematilpasninger",
    registrationQuestions: "Registreringskjemaspørsmål",
    regret: "Angre",
    remaining: "Ledig",
    rememberToAddPriceCategory: "Husk å legge til priskategori",
    remove: "Fjern",
    reorderColumn: "Oppdater rekkefølge",
    repeatEvery: "Gjenta hver",
    repeatingCalendarEvent: "Repeterende kalenderhendelse",
    repeatOn: "Gjenta på",
    rescheduled: "Endret tidspunkt",
    response: "Respons",
    ResponseCode: "Responskode",
    responseFromNetaxept: "Respons fra Netaxept",
    ResponseSource: "Responskilde",
    ResponseText: "Responstekst",
    restore: "Gjenopprett",
    revenue: "Omsetning",
    revenueExcludingVat: "Omsetning eks. MVA",
    revenueIncludingVat: "Omsetning ink. MVA",
    RoundingAmount: "Avrundingsbeløp",
    rowSelected: "Rad valgt",
    rowsPerPage: "Rader pr. side",
    rowsPrPage: "Rader pr. side",
    rowsSelected: "Rader valgt",
    rubicIntegration: "Rubicintegrasjon",
    runQuery: "Kjør spørring",
    saleLimit: "Plasser",
    sales: "Salg",
    salesPointId: "Salgspunkt-ID",
    saturday: "Lørdag",
    saturdays: "Lørdager",
    save: "Lagre",
    saveToKdr: "Lagre (KDR)",
    scanner: "Skanner",
    scheduleEvent: "Kalenderhendelse",
    scheduleEventException: "Unntak for kalenderhendelse",
    scheduleEvents: "Kalenderhendelser",
    showDescriptionInReceipt: "Vis beskrivelsen på kvittering (kjøpsportal og e-postkvittering)",
    search: "Søk",
    noAvailableProductsYet: "Ingen tilgjengelige produkter enda",
    seasonal_pass: "Sesongkort",
    seasonalPasses: "Sesongkort",
    selectDiscounts: "Velg rabatter",
    seasonalPasss: "Årskort",
    SecurityInformation: "Sikkerhetsinformasjon",
    seeAll: "Se alle",
    eventIsCancelled: "Hendelsen er avlyst",
    seeTableData: "Se tabelldata",
    createTestOrder: "Lag testordre",
    selected: "Valgt",
    selectedMuseumChanged: "Valgt museum endret",
    selectFileFormat: "Velg filformat",
    send: "Send",
    noFamilyDiscount: "Ingen familierabatt satt opp",
    clickToSetUp: "Klikk for å opprette",
    sent: "Sendt",
    addNewRule: "Legg til ny regel",
    rule: "Regel",
    minimum: "Minimum",
    maximum: "Maksimum",
    severity: "Nivå",
    lowestLevel: "Laveste nivå",
    settings: "Innstillinger",
    shortcutTo: "Snarvei til",
    shortDescription: "Kort beskrivelse - Vises øverst i kjøpsportalen (valgfritt felt)",
    showAll: "Vis alle",
    showArchived: "Kun arkiverte",
    showColumns: "Vis kolonner",
    showColumnSelector: "Vis kolonnevelger",
    showFilters: "Vis filtre",
    sold: "Solgt",
    sortAscending: "Sortér synkende",
    sortDescending: "Sortér stigende",
    standard: "Standard",
    standardDebitAccount: "Standard debetkonto",
    startDate: "Startdato",
    startsWith: "Begynner på",
    startTime: "Fra",
    status: "Status",
    subTicket: "Enkeltbillett",
    subTickets: "Enkeltbilletter",
    sum: "Sum",
    sumExcludingVat: "Sum (eks. mva)",
    sumIncludingVat: "Sum (ink. mva)",
    Summary: "Oppsummering",
    sunday: "Søndag",
    sundays: "Søndager",
    TerminalInformation: "Terminalinformasjon",
    termsOfSaleUrl: "URL til kjøpsbetingelser",
    testEnvironment: "Testmiljø",
    testOrder: "Testordre",
    testOrders: "Testordrer",
    testTicketsSold: "Testbilletter solgt",
    text: "Tekst",
    confirmed_pay_later: "Bekreftet (betal senere)",
    pending: "Venter",
    processed: "Prosessert",
    annulled: "Annullert",
    credited: "Kreditert",
    availableProducts: "Tilgjengelige produkter",
    text2: "Tekst2",
    textFileVisma: "Tekstfil (Visma)",
    theCreditNoteHasOrderId: "Kreditnotaen har ordre-ID",
    theseCanBeOverriddenOnEachProduct: "Kan overstyres på produktnivå",
    thisIsACreditNoteForOrder: "Dette er en kreditnota for ordre med id",
    thisIsAPartialCreditNoteForOrder: "Dette er en delkreditnota for ordre med id",
    thisMonth: "Denne måneden",
    thisOrderIsCredited: "Denne ordren er kreditert!",
    thisOrderIsPartiallyCredited: "Denne ordren er delkreditert!",
    thisWeek: "Denne uken",
    thisYear: "Dette året",
    thursday: "Torsdag",
    thursdays: "Torsdager",
    thusFar: "Hittil",
    tickets: "Billetter",
    ticketSales: "Billettsalg",
    timestamp: "Tidspunkt",
    sentEmails: "Sendte mailer",
    sendTestMail: "Send testmail",
    sendToAll: "Send til alle",
    sendToSelection: "Send til utvalg",
    sendMailToAllCustomers: "Send epost til alle kunder",
    sendMailToSelectedCustomers: "Send epost til et utvalg kunder",
    emailCustomerCardInfoText: "Benytt dette skjemaet for å sende en epost til alle som har kjøpt dette produktet.",
    emailSomeCustomerCardInfoText: "Benytt dette skjemaet for å sende en epost til alle som har kjøpt billetter til ",
    emailCustomerCardHelperText: "Bilde  og farge som brukes vil være samme bildet som er brukt på produktet.",
    heading: "Overskrift",
    subject: "Emne",
    sentBy: "Sendt av",
    mailId: "Mail-ID",
    sentAt: "Sendt klokkeslett",
    ticketSalesForNextTenEvents: "Billettsalg for de neste ti arrangementer",
    ticketSold: "Billett solgt",
    ticketsSold: "Billetter solgt",
    ticketsSoldProdTest: "Billetter solgt (prod/test)",
    ticketStatus: "Billettstatus",
    time: "Tid",
    timeFromTo: "Tid fra-til",
    times: "Ganger",
    Timestamp: "Tidsstempel",
    timesValidated: "Ganger validert",
    relatedProducts: "Relaterte produkter",
    noAvailableProducts: "Ingen tilgjengelige produkter",
    title: "Tittel",
    to: "Til",
    toAddDepartments: "For å legge til avdelinger",
    toAddPriceCategories: "For å legge til priskategorier",
    toDate: "Til dato",
    toDay: "I dag",
    today: "i dag",
    toDefine: "For å definere",
    toDefineTermsOfSaleUrl: "For å definere URL til kjøpsbetingelser",
    token: "Token",
    Total: "Total",
    total: "Total",
    TransactionId: "Transaksjons-ID",
    transactionId: "Transaksjons-ID",
    TransactionLogLine: "Transaksjon",
    TransactionReconRef: "Transaksjons-ref",
    tuesday: "Tirsdag",
    tuesdays: "Tirsdager",
    type: "Type",
    umbrellaMuseum: "Selskap",
    unitsSold: "Antall solgt",
    unlockOrder: "Lås opp ordre",
    kdrCompatibleTicketCodes: "Billettkoder med KDR-støtte",
    duellCompatibleTicketCodes: "Billettkoder med Duell-støtte",
    ticketCodes: "Billettkoder",
    unsavedChanges: "Ulagrede endringer",
    unsort: "Avsortér",
    familyDiscount: "Familierabatt",
    familyDiscounts: "Familierabatter",
    update: "Oppdater",
    updated: "Oppdatert",
    updatedBy: "Oppdatert av",
    updatedFor: "Oppdatert for",
    urlForEnglishTermsAndConditions: "Url til engelske kjøpsbetingelser",
    urlToProductStats: "URL til billettoversikt (i portal)",
    urlToSalesPortal: "URL til kjøpsportal",
    urlToTicketStatus: "URL til billettstatus (i portal)",
    user: "Bruker",
    userPortal: "Kjøpsportal",
    userPortalDesignSettings: "Design",
    userPortalRegistrationSettings: "Registreringssteg",
    jointDiscounts: "Fellesrabatter",
    jointDiscount: "Fellesrabatt",
    noDiscountsYet: "Ingen rabatter enda",
    jointProductsDiscount: "Felles produktrabatt",
    jointProductsDiscounts: "Felles produktrabatter",
    jointProducts: "Tilknyttede produkter",
    noJointProductsYet: "Ingen tilknyttede produkter enda",
    uniqueSellingPoint: "Salgsargument",
    uniqueSellingPoints: "Salgsargumenter",
    uniqueSellingPointsSubheading: "Velg salgsargumenter du vil fremme i kjøpsportalen (Maks 3stk)",
    validated: "Validert",
    validationAttempts: "Valideringsforsøk",
    validFrom: "Gyldig fra",
    validity: "Gyldighet",
    validTo: "Gyldig til",
    value: "Verdi",
    vat: "MVA",
    change: "Endre",
    productSearch: "Produktsøk",
    validation: "Validering",
    vatAmount: "MVA-beløp",
    verificationCode: "Sikkerhetskode",
    Vipps: "Vipps",
    Visa: "Visa",
    visitorNumbers: "Besøkstall",
    visitorNumbersPrPriceCategory: "Besøkstall pr. priskategori",
    visma: "Visma",
    vismaSettings: "Vismainnstillinger",
    voucher: "Bilag",
    voucherExport: "Bilagseksport",
    voucherNumbers: "Bilagstall",
    vouchers: "Bilag",
    voucherSettings: "Bilagsinnstillinger",
    voucherSettingsUpdated: "Bilagsinnstillinger oppdatert",
    wednesday: "Onsdag",
    wednesdays: "Onsdager",
    week: "Uke",
    weekdaily: "Hver ukedag",
    weekday: "Ukedag",
    weekly: "Ukentlig",
    wholeDay: "Ingen klokkeslett",
    wholeOf: "Hele",
    year: "År",
    info: "Info",
    debug: "Debug",
    notSet: "Ikke satt",
    yearly: "Årlig",
    yes: "Ja",
    youNeedToSelectAMuseumToChangeSettings: "For å se og endre innstillinger, må du først velge et museum eller selskap",
    youNeedToSelectAMuseumToChangeJointDiscounts: "For å se og endre fellesrabatter, må du først velge et museum eller selskap",
    noDiscountCodes: "Ingen rabattkoder eksisterer. Opprett rabattkoder via innstillinger.",
    warning: "Advarsel",
    refused_by_issuer_confiscate: "Avvist av kortutsteder (konfisker kortet (ingen svindel))",
    refused_by_issuer_confiscate_fraud: "Avvist av kortutsteder (konfisker kortet (svindel))",
    refused_by_issuer_invalid_transaction: "Avvist av kortutsteder (ugyldig transaksjon)",
    refused_by_issuer_invalid_account: "Avvist av kortutsteder (ugyldig kontonummer)",
    refused_by_issuer_invalid_issuer: "Avvist av kortutsteder (ugyldig kortutsteder)",
    refused_by_issuer_card_lost: "Avvist av kortutsteder (mistet kort)",
    refused_by_issuer_card_stolen: "Avvist av kortutsteder (stjålet kort)",
    refused_by_issuer_closed_account: "Avvist av kortutsteder (lukket konto)",
    refused_by_issuer_transaction_not_allowed: "Avvist av kortutsteder (transaksjonen ikke tillatt for kortholder)",
    refused_by_issuer_stop_payment: "Avvist av kortutsteder (stopp betalingen)",
    refused_by_issuer_callback_of_authorization: "Avvist av kortutsteder (tilbakekalling av autorisasjonsordre)",
    refused_by_issuer_callback_of_all_authorizations: "Avvist av kortutsteder (tilbakekalling av alle autorisasjonsordre)"
}

const errorMessagesNO = {
    "Order already registered in KDR. No change": "Ordre allerede registrert i KDR. Ingen endring",
    "Order successfully registered in KDR": "Lagring av ordre i KDR vellykket",
    "Conflict. An order with that id already exists, but the values are different": "Konflikt! En ordre med den ID'en"
        + " eksisterer allerede",
    "Menu id missing": "Meny-ID mangler",
    "Order is not confirmed. Can not save order to KDR": "Ordre er ikke bekreftet. Kan ikke lagre til KDR",
    "Could not find order": "Kunne ikke finne ordre",
    "Already registered in KDR. Skipping KDR call": "Allerede registrert i KDR. Lagring avbrutt",
    "Request failed with status code 400": "Forespørsel feilet med statuskode 400",
    "Request failed with status code 422": "Forespørsel feilet med statuskode 422. Påkrevd felt som mangler?",
    "Request failed with status code 405": "Forespørsel feilet med statuskode 405",
    "Request failed with status code 502": "Forespørsel feilet med statuskode 502. Feil på mellomledd eller ekstern tjeneste?",
    "Not enough tickets remaining": "Ikke nok billetter igjen",
}

const helperTextNO = {
    processedWithNetaxeptHelperText: "Antall ganger selve transaksjonen er forsøkt gjennomført via Netaxept. Kortutstedere (bl.a. VISA) kan pålegge gebyr/bøter hvis en transaksjon blir forsøkt gjennomført for mange ganger. Det er for eksempel ikke lov å prøve å gjennomføre en allerede avvist transaksjon. Andre transaksjoner kan forsøkes opp til 16 ganger. For å unngå gebyr er det lagt inn en sperre i Museumsbillett.",
    backgroundColorHelperText: "Synlig når bakgrunnsbilde ikke er satt",
    colorHexCodeInputHelperText: 'Ugyldig fargekode! koden skal være i hex-format og skal starte med "#"',
    creditOrderCommentRequiredText: "Kommentar påkrevd for å kunne kreditere ordre!",
    customerTableSeachHelperText: "Søk på epost eller navn",
    createOrderHelperText: "Lag ny ordre. NB! IKKE test-ordre!",
    debitAccountMissingText: "Hvis et salg blir gjort med et betalingskort som ikke har egen debetkonto definert, brukes standard debetkonto. Hvis standard debetkonto heller ikke er definert, kan ikke bilag genereres og eksporteres.",
    deleteEntranceTicketDialogText: "Er du sikker på at du vil slette denne inngangsbilletten",
    deleteJointProductsDiscountDialogText: "Er du sikker på at du vil slette denne fellesrabatten? Dette vil også løsrive eventuelle sammenkoblede produkter",
    deleteEventDialogText: "Er du sikker på at du vil slette dette arrangementet",
    deleteProductGroupTooltipHelper: "Kan ikke slette denne varegruppen, da den er i bruk av én eller flere priskategorier.",
    deleteSeasonalPassDialogText: "Er du sikker på at du vil slette dette sesongkortet",
    deleteVouchersDialogText: "Er du sikker på at vil slette valgte regnskapsbilag",
    discountCodeModuleSubheading: "Velg hvilke rabattkoder du ønsker at skal være mulig å benytte for dette produktet.",
    discountCodesHelperText: "Definer ønskede rabattkoder. Aktivering og deaktivering av rabattkoder skjer i forbindelse med oppsett av produkt.",
    enableNewsletterSwitcherHelperText: "Gir valg til brukeren ved registrering om å abbonere på nyhetsbrevet tilknyttet selskap eller museum.",
    emailNotificationAddressHelperText: "Fyll inn e-postadresse dersom du ønsker varsel hver gang ny ordre kommer inn",
    freeTextFieldHelperText: "Aktiver dette dersom du ønsker at kundene skal kunne legge igjen en tekst (for eksempel kommentar) på orderen.",
    generalSettingsModuleHelperText: "Overordnede innstillinger for produktet",
    generateAndExportVouchersTooltip: "Generér manglende bilag og last ned Vismafil",
    generateAndExportVouchersTooltipDisabled: "For å kunne laste ned Vismafil, må format settes til Visma under INNSTILLINGER -> Bilag",
    headerColorTooltip: "Bakgrunnsfarge på tittelfelt i kjøpsportal",
    eventAvailablePeriod: "Perioden arrangementet er tilgjengelig",
    linkToProductDetailsTooltip: "Gå til dette produktets dashboard",
    linkToProductPortalTooltip: "Se dette produktet i kjøpsportal",
    mailchimpMuseumHelperText: "Gjelder bare for valgt museum",
    mailchimpSettingsHelperText: "Mailchimp audience id og api key for utsending av nyhetsbrev finner du ved å logge på din Mailchimp konto",
    noUserAccessText: "Din eKultur-bruker har ikke tilgang til noen museer i Museumsbillett. Ta kontakt med en eKultur-administrator.",
    noPaymentCardsAddedText: "Ingen debetkonti registrert på betalingskort. Standard debetkonto vil derfor bli brukt på alle salg.",
    orderCommentHelperText: "For eksempel grunnen til at en ordre er kreditert",
    orderTableSeachHelperText: "Søk på ordreID, epost eller navn",
    ownUrlForMuseumHelperText: "Må aktiveres for å kunne redigere feltet under",
    paymentTypeManualHandlingHelperText: "Manuell håndtering er for salg der betaling håndteres manuelt, eller for reservasjon av gratisprodukter. Epostvarsling er funksjonalitet som vil komme på sikt.",
    paymentTypeModuleHelperText: "Betalingsmåter som kan tilgjengeliggjøres manuelt på hvert produkt i alle museer under et selskap",
    paymentTypeNetaxeptHelperText: "Krever egen avtale med Netaxept. Hvert selskap må ha egen avtale.",
    pointOfSaleSystemsModuleHelperText: "Integrasjon mot kassasystemer (kun KDR i første omgang)",
    priceAmountHelperText: "Pris i kr inkl. MVA",
    relatedProductsSubheading: "Produkter som vises under \"se også\" i kjøpsportal",
    relatedProductsHeading: "Overskrift som vises i kjøpsportal",
    familyDiscountsHelperText: "Sett opp regler for familierabatter, i PRIORITERT rekkefølge (i de tilfellene en ordre oppfyller kravene til mer enn én rabatt, vil den øverste gjelde)",
    portalDesignSettingsHelperText: "Mørke farger vil gjøre tekstfargen hvit, og lyse farger vil gjøre tekstfargen svart.",
    pricecategoriesSettingsHelperText: "Priskategorier (for hele selskapet/konsolideringen) som knyttes til priser på produkter. Hver priskategori må være knyttet til én varegruppe.",
    priceCategoryNameHelperText: "Alle produkter må ha priskategori (også gratisprodukter)",
    priceDescriptionHelperText: "Valgfri beskrivende tekst på prisvalg i kjøpsportal",
    priceNameHelperText: "Prisnavnet som vises til sluttbruker i kjøpsportal og på billetter",
    productCategoriesAndPricesDescriptionHelperText: "Beskrivelse på prismodul i kjøpsskjema",
    productCategoriesAndPricesModuleHelperText: "Valgene som vises i prisvelger i kjøpsportal. Alle produkter må ha priskategori (selv gratisprodukter).",
    productCategoriesAndPricesTitleHelperText: "Tittel på prismodul i kjøpsskjema",
    productColorHeaderChangeHelperText: "Velg bakgrunnsfarge på tittelfelt i kjøpsportal",
    productDescriptionHelperText: "Beskrivelse av produktet. Vises under tittel i kjøpsportal.",
    productGroupsSettingsHelperText: "Varegrupper med merverdigavgift for selskap/konsolidering. Hver varegruppe kan ha flere priskategorier knyttet til seg.",
    productImageHelperText: "Bilde som blir vist på forsiden  i kjøpsportal.",
    productLinkCardModuleHelperText: "Tilpass hvordan lenken den til dette produktet skal se ut, dersom det blir lenket til andre produkter i forbindelse med fellesrabatt eller relaterte produkter",
    productNameHelperText: "Påkrevd felt! Navn på selve produktet. Vises som tittel i kjøpsportal og på billetter.",
    productPaymentTypesModuleHelperText: "Betalingsmetode som skal være tilgjengelig i kjøpsportal for hvert produkt",
    recurringScheduleEventLockedHelperText: "Låst da det allerede er solgt billetter til denne hendelsen",
    registrationFormCustomizationHelperText: "Utvid registreringsskjemaet i kjøpsportalen med egne sjekkbokser og tekstfelt",
    registrationFormQuestionHelperText: "Få svar på spørsmål i forbindelse med orderen. Kunden svarer på spørsmålet i registreringssteget.",
    scheduleEventEndDateHelperText: "Begrenset støtte for heldagshendelser over flere dager!",
    scheduleEventNameHelperText: "Valgfritt felt. Beskrivende navn på hendelsen. Vises i datovelger i kjøpsportal og på billetter.",
    scheduleEventSaleLimitHelperText: "Valgfri begrensning på denne hendelsen (inkl. gjentagende)",
    scheduleEventMaxTicketPerCustomerHelperText: "Valgfri Begrensning på hvor mange billetter en kunde kan kjøpe til denne hendelsen (inkl. gjentagende)",
    jointProductsHelperText: "Produkter med felles produktrabatt",
    productFamilyDiscountsModuleHelperText: "Velg hvilke familierabatter som skal gjelde for dette produktet",
    availableJointProductsHelperText: "Ledige produkter som kan kobles til denne fellesrabatten",
    scheduleEventsDescriptionHelperText: "Beskrivelse på kalendermodul i kjøpsskjema",
    scheduleEventsModuleEntranceTicketHelperText: "Åpningstidene til museet inngangsbilletten gjelder for",
    scheduleEventsModuleEventHelperText: "Tidsperioden(e) arrangementet gjelder for",
    scheduleEventsTitleHelperText: "Tittel på kalendermodul i kjøpsskjema",
    termsOfSaleCommonUrlHelperText: "Gjelder for alle museer i samme konsolidering, hvis ikke overstyrt på enkeltmuseer",
    termsOfSaleMuseumUrlHelperText: "Gjelder bare for valgt museum",
    onlyNonJointProductsHelperText: "Obs! Produkter kan kun være knyttet til én fellesrabatt av gangen!",
    termsOfSaleUrlHelperText: "Adresse til ekstern side med kjøpsbetingelser",
    museumSettingsHelperText: "Informasjonsfelter til bruk bare ut mot publikum. Dersom felt på museumsnivå ikke er satt, brukes verdien satt på selskapsnivå. Visningsnavn kommer automatisk fra Ekultur og trenger ikke overskrives.",
    validityModuleHelperText: "Innstillinger for når billetten skal være gyldig",
    vismaSettingsHelperText: "Innstillinger knyttet til generering og eksport av bilag",
    vismaSettingsDepartmentHelperText: "Velg avdeling som overstyrer innstillinger satt på andre nivåer",
    vismaFieldsSubheader: "Kontonummer og andre Vismarelaterte felter i eksportfil",
    vismaVoucherSettingsModuleHelperText: "Kontonummer og andre verdier brukt i eksportfil til Visma",
    voucherExportModuleHelperText: "Filformat for eksport av regnskapsbilag (foreløpig kun støtte for Visma)",
    creditOrderWithRubicMembershipWarning: "Denne kunden opprettet medlemskap i rubic via denne ordren.\n" +
        "              Medlemskapet blir ikke automatisk avsluttet ved kreditering. Avslutt medlemskapet manuellelt via Rubic.",
    testEmailHelperText: "Send en forhåndsvisning av mailen til ønsket adresse",
    uniqueSellingPointsHelperText: "Sett opp ikoner sammen med en beskrivelse som vises i kjøpsportalen.",
}

const resources = {
    "nb-NO": {
        "bpn.base": {
            ...commonNbNO,
            ...errorMessagesNO,
            ...helperTextNO,
        },
        "bpn.calendar": {
            ...commonNbNO,
            ...errorMessagesNO,
            isAllDay: "Ingen klokkeslett",
        },
        "bpn.dashboard": {
            ...commonNbNO,
            ...errorMessagesNO,
            whenAreTicketsSold: "Når i døgnet selges billetter",

        },
        "bpn.entrance": {
            ...commonNbNO,
            ...errorMessagesNO,
            ...helperTextNO,
            entranceDashboard: "Inngangsbilletter",
        },
        "bpn.events": {
            ...commonNbNO,
            ...errorMessagesNO,
            ...helperTextNO,
            thisEventHasNoEventDates: "Dette arrangementet har ingen arrangementsdatoer",
            thisEventHasNoPrices: "Det er ikke satt noen priser på dette arrangementet",
            upcomingEvents: "Kommende arrangementer",
            noUpcomingEvents: "Ingen kommende arrangementer",
        },
        "bpn.seasonalpasses": {
            ...commonNbNO,
            ...helperTextNO,
            ...errorMessagesNO,
            ...errorMessagesNO,
            noSeasonalPassesYet: "Ingen årskort enda",
            seasonalPass: "Årskort",
        },
        "bpn.menu": {
            ...commonNbNO,
            ...errorMessagesNO,
        },
        "bpn.other": {
            ...commonNbNO,
            ...errorMessagesNO,
            otherDashboard: "Andre produkter",
        },
        "bpn.producteditor": {
            ...commonNbNO,
            ...errorMessagesNO,
            ...helperTextNO,
            blankForNoLimitations: "Blank for ingen begrensninger",
            daysValidAfterPurchase: "Dager gyldig etter kjøp",
            dynamicValidity: "Dynamisk gyldighet",
            kdrEnabledForProduct: "Aktivér KDR-integrasjon for produkt",
            kdrPrice: "Pris hentet fra KDR",
            kdrName: "Navn hentet fra KDR",
            linkCardHeading: "Overskrift",
            linkCardSubHeading: "Underoverskrift",
            mustBeFilled: "Må fylles ut",
            mustHaveKdrProduct: "Må ha KDR-produkt/pris",
            nulls: "",
            productValidity: "Gyldighet",
            isAvailableForSale: "Er tilgjengelig for salg",
            saveAndDepublish: "Lagre og avpublisér",
            saveAndPublish: "Lagre og publisér",
            theEntranceTicket: "Inngangsbilletten",
            theEvent: "Arrangementet",
            theSeasonalPass: "Årskortet",
            seasonalPass: "Årskort",
            theTimePeriod: "Tidsperioden",
            newsletter: "Nyhetsbrev",
            rubicIntegration: "Rubic integrasjon",
            productAsGift: "Kjøp som gave",
            enableRubicIntegration: "Aktiver rubic integrasjon",
            enableRubicIntegrationHelperText: "Aktiver dette dersom du ønsker at produktet skal knyttes til vennemedlemskap.",
            productAsGiftHelperText: "Gir kjøperen valget om å kjøpe som gave til en annen. For- og etternavn på mottaker oppgis i registreringssteget.",
            productImage: "Produktbilde",
            deleteProductImage: "Slett",
            unsavedChangesWarning: " Det finnes ulagrede endringer! Du må trykke lagre før endringene vises i forhåndsvisningen.",
            unsavedProductWarning: "Produktet må lagres før forhåndsvisningen kan vises",
            previewUrl: "URL til kjøpsportal: ",
            copyUrl: "Kopier link",
            productPageShortDescription: "Vis ekstra informasjon på produktforside"
        },
        "bpn.sales": {
            ...commonNbNO,
            ...errorMessagesNO,
            salesDashboard: "Salg",
        },
        "bpn.settings": {
            ...commonNbNO,
            ...errorMessagesNO,
            ...helperTextNO,
        },
    },
}

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: "nb-NO",
        keySeparator: false,
        debug: process.env.NODE_ENV === "development",
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        saveMissing: true,
    }).then(() => console.info("i18n init success"))

export default i18n
