import {CardActions, FormControlLabel, Grid, Switch, TextField} from "@mui/material"
import makeStyles from '@mui/styles/makeStyles';
import React, {useEffect, useState} from "react"
import _ from "lodash"
import {useProductEditorTranslation} from "../productEditorTranslationContext"
import CheckBoxIcon from "@mui/icons-material/CheckBox"
import Button from "@mui/material/Button"
import IconButton from "@mui/material/IconButton"
import DeleteIcon from "@mui/icons-material/Delete"
import {getRandomInt} from "../../helper-functions/getRandomInt"
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked"
import Typography from "@mui/material/Typography"
import {useAxiosBpn} from "../../axios"
import {useMuseumSelectorState} from "../../museum-selector/museumSelectorContext"
import {PRODUCT_TYPE_SEASONAL_PASS} from "../../baseContext"
import TranslatableTextField from "../TranslatableTextField"

const useStyles = makeStyles(theme => ({
    textfield: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    addButton: {
        marginTop: theme.spacing(1),
    },
    alternativeContainer: {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
        display: "grid",
        gridTemplateColumns: "auto auto",
        gridTemplateRows: "auto",
        width: "100%",
        gridTemplateAreas: `
        "alternativeType translateableField deleteButton"
        `,
    },
    alternativeType: {
        gridArea: "alternativeType",
        margin: theme.spacing(1),
    },
    translateableField: {
        gridArea: "translateableField",
        justifySelf: "center",
        alignSelf: "center",
    },
    deleteButton: {
        gridArea: "deleteButton",
        height: "70px",
    },
    gridItem: {
        width: "100%",
    },
    switchLabel: {
        fontSize: "0.9em",
    },
    optionsContainer: {
        width: "100%",
        textAlignLast: "right",
    },
}))

const RegistrationFormAddons = ({product, unsavedChanges, setUnsavedChanges, updateProductState, productType, museumSettingsData}) => {
    const t = useProductEditorTranslation()
    const classes = useStyles()
    const [data, setData] = useState({})
    const [freetextFieldPlaceholderText, setFreetextFieldPlaceholderText] = useState()
    const [registrationFormQuestion, setRegistrationFormQuestion] = useState()
    const [hasFreetextField, setHasFreetextField] = useState(false)
    const [hasQuestionAnswer, setHasQuestionAnswer] = useState(false)
    const [alternatives, setAlternatives] = useState([])

    useEffect(() => {
        if (product) {
            setData(product)
            setHasFreetextField(product?.registrationFormFreetextField?.value?.length > 0)
            setFreetextFieldPlaceholderText(product?.registrationFormFreetextField || {
                value: t("comment"),
                translations: [],
            })
            setRegistrationFormQuestion(product?.registrationFormQuestion || "")
            setHasQuestionAnswer(product?.registrationFormQuestion?.length > 0)
            if (product.registrationFormAnswers?.length > 0) {
                setAlternatives(JSON.parse(product.registrationFormAnswers))
            }
        }
    }, [product, t])

    const onTranslatableFieldChanged = (propertyKey, updatedValue) => {
        setUnsavedChanges(true)
        updateProductState({...data, [propertyKey]: updatedValue})
    }

    useEffect(() => {
        if (alternatives) {
            setData(prev => ({...prev, "registrationFormAnswers": JSON.stringify(alternatives)}))
            if (alternatives?.length < 1) {
                setData(prev => ({...prev, "registrationFormQuestionMandatory": false}))
            }
        }
    }, [alternatives])

    useEffect(() => {
        if (registrationFormQuestion) {
            setData(prev => ({...prev, "registrationFormQuestion": registrationFormQuestion}))
        }
    }, [registrationFormQuestion])

    const onToggleHasFreetextField = (e) => {
        if (!e.target.checked) {
            setData(prev => ({...prev, "registrationFormFreetextField": {value: "", translations: []}}))
        } else {
            setData(prev => ({...prev, "registrationFormFreetextField": freetextFieldPlaceholderText}))
        }

        setHasFreetextField(e.target.checked)
    }

    const onToggleQuestionAnswers = (e) => {
        if (!e.target.value.checked) {
            setData(prev => ({
                ...prev,
                "registrationFormQuestion": "",
                "registrationFormAnswers": "",
                "registrationFormQuestionMandatory": false,
                "registrationFormMultipleChoice": false,
            }))
        } else {
            setData(prev => ({
                ...prev,
                "registrationFormQuestion": registrationFormQuestion,
                "registrationFormAnswers": JSON.stringify(alternatives),
                "registrationFormQuestionMandatory": data?.registrationFormQuestionMandatory || false,
                "registrationFormMultipleChoice": data?.registrationFormMultipleChoice || false,
            }))
        }

        setHasQuestionAnswer(e.target.checked)
    }

    const handleToggleSwitch = (e) => {
        e.persist()
        setData(prev => ({...prev, [e.target.name]: e.target.checked}))
    }

    const hasNewsLetterConfigured = () => {
        return (museumSettingsData?.museum?.mcApiKey && museumSettingsData?.museum?.mcAudienceId) ||
            (museumSettingsData?.umbrella?.mcApiKey && museumSettingsData?.umbrella?.mcAudienceId)
    }

    const hasConfiguredRubic = () => {
        return museumSettingsData?.museum?.rubicApiUrl || museumSettingsData?.umbrella?.rubicApiUrl
    }

    const handleSubmit = () => {
        if (!_.isEqual(data, product)) {
            updateProductState(data)
            if (!unsavedChanges) {
                setUnsavedChanges(true)
            }
        }
    }

    const handleAlternativeChange = (e, alternativeIndex) => {
        setAlternatives(prev => {
            let tmp = [...prev]
            if (!tmp[alternativeIndex]) return prev
            tmp[alternativeIndex].value = e.target.value
            return tmp
        })
    }

    const addAlternative = () => {
        setAlternatives([...alternatives, {key: getRandomInt(0, 99999), value: ""}])
    }

    const removeAlternative = (alternative) => {
        setAlternatives(alternatives.filter(alt => alt.key !== alternative.key))
    }

    return (
        <form noValidate
              autoComplete="off"
              onBlur={handleSubmit}>
            <Grid container
                  style={{marginBottom: 4}}
                  alignItems="center"
                  spacing={1}>
                <Grid item>
                    <Typography><strong>{t("freetextField")}</strong></Typography>
                    <Typography variant="body2"
                                color="textSecondary">{t("freeTextFieldHelperText")}</Typography>
                    <FormControlLabel control={<Switch color="primary"
                                                       name={"registrationFormFreetextField"}
                                                       checked={hasFreetextField}
                                                       onChange={onToggleHasFreetextField}/>}
                                      label={t("comment")}
                                      classes={{label: classes.switchLabel}}/>
                    {hasFreetextField && <TranslatableTextField
                        name={"registrationFormFreetextField"}
                        value={freetextFieldPlaceholderText}
                        onChange={(updatedValue) => onTranslatableFieldChanged("registrationFormFreetextField",
                            updatedValue)}
                        label={t("freeTextFieldPlaceholderText")}/>}
                </Grid>

                <Grid item
                      className={classes.gridItem}>
                    <Typography><strong>{t("question")}</strong></Typography>
                    <Typography variant="body2"
                                color="textSecondary">{t("registrationFormQuestionHelperText")}</Typography>
                    <FormControlLabel control={<Switch color="primary"
                                                       name={"registrationFormQuestion"}
                                                       checked={hasQuestionAnswer}
                                                       onChange={onToggleQuestionAnswers}/>}
                                      label={t("question")}
                                      classes={{label: classes.switchLabel}}/>

                    {hasQuestionAnswer &&
                        <>
                            <TextField variant="filled"
                                       value={registrationFormQuestion}
                                       name={"registrationFormQuestion"}
                                       label={t("question")}
                                       fullWidth
                                       onChange={(e) => setRegistrationFormQuestion(e.target.value)}/>

                            {alternatives.map((alternative, index) =>
                                <div className={classes.alternativeContainer} key={alternative.key}>
                                    {data?.registrationFormMultipleChoice
                                     ? <CheckBoxIcon color={"disabled"}
                                                     className={classes.alternativeType}/>
                                     :
                                     <RadioButtonCheckedIcon color={"disabled"}
                                                             className={classes.alternativeType}/>}
                                    <TextField variant="filled"
                                               name={"registrationFormAnswers"}
                                               value={alternative.value}
                                               onChange={(e) => {
                                                   handleAlternativeChange(e, index)
                                               }}
                                               label={`${t("alternative")} ${(index + 1)}`}
                                               fullWidth/>
                                    <IconButton
                                        className={classes.deleteButton}
                                        aria-label="delete"
                                        onClick={() => removeAlternative(alternative)}
                                        size="large">
                                        <DeleteIcon/>
                                    </IconButton>
                                </div>,
                            )}

                            <Button variant="contained"
                                    color={"primary"}
                                    onClick={addAlternative}
                                    className={classes.addButton}> + Alternativ</Button>
                        </>}
                </Grid>

                {hasQuestionAnswer &&
                    <CardActions className={classes.optionsContainer}>
                        <Grid item
                              className={classes.optionsContainer}>
                            <FormControlLabel control={<Switch color="primary"
                                                               checked={(data?.registrationFormQuestionMandatory && alternatives?.length > 0) || false}
                                                               name={"registrationFormQuestionMandatory"}
                                                               onChange={handleToggleSwitch}/>}
                                              label={t("mandatoryAnswer")}
                                              disabled={!registrationFormQuestion || registrationFormQuestion === "" || alternatives?.length < 1}
                                              classes={{label: classes.switchLabel}}/>
                            <FormControlLabel control={<Switch color="primary"
                                                               checked={data?.registrationFormMultipleChoice || false}
                                                               disabled={alternatives?.length < 1}
                                                               onChange={handleToggleSwitch}
                                                               name={"registrationFormMultipleChoice"}/>}
                                              label={t("multipleChoice")}
                                              classes={{label: classes.switchLabel}}/>
                        </Grid>
                    </CardActions>}
            </Grid>
            <Grid item>
                {museumSettingsData && hasConfiguredRubic() && productType === PRODUCT_TYPE_SEASONAL_PASS &&
                    <>
                        <Typography className={classes.textfield}>
                            <strong>{t("rubicIntegration")}</strong>
                        </Typography>
                        <Typography variant="body2"
                                    color="textSecondary">
                            {t("enableRubicIntegrationHelperText")}
                        </Typography>
                        <Grid container
                              spacing={2}
                              justifyContent="space-between">
                            <Grid item
                                  xs={12}
                                  sm={6}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={data?.rubicIntegrationEnabled || false}
                                            onChange={handleToggleSwitch}
                                            disabled={data?.isGiftProduct}
                                            name="rubicIntegrationEnabled"
                                            color="primary"
                                        />
                                    }
                                    label={t("enableRubicIntegration")}
                                    classes={{label: classes.switchLabel}}
                                />
                            </Grid>
                        </Grid>
                    </>}
            </Grid>
            <Grid item>
                <>
                    <Typography className={classes.textfield}>
                        <strong>{t("productAsGift")}</strong>
                    </Typography>
                    <Typography variant="body2"
                                color="textSecondary">
                        {t("productAsGiftHelperText")}
                    </Typography>
                    <Grid container
                          spacing={2}
                          justifyContent="space-between">
                        <Grid item
                              xs={12}
                              sm={6}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={data?.isGiftProduct || false}
                                        onChange={handleToggleSwitch}
                                        name="isGiftProduct"
                                        disabled={data?.rubicIntegrationEnabled}
                                        color="primary"
                                    />
                                }
                                label={t("productAsGift")}
                                classes={{label: classes.switchLabel}}
                            />
                        </Grid>
                    </Grid>
                </>
            </Grid>
        </form>
    );
}

export default RegistrationFormAddons
